import React, {FormEvent, useState} from 'react';
import {Auth} from "../../../../util/sessionUtil";
import {SessionRef} from "../../../../type/data/SessionRef";
import {startValidationSession} from "../../../../util/restUtil";
import {Button, Col, Form, Row, Stack} from "react-bootstrap";
import {isEmptyString} from "../../../../util/stringUtil";
import {BsArrowRightCircleFill, BsSearch} from "react-icons/bs";
import Loading from "../../../controls/Loading";
import LocalizedText from "../../../controls/LocalizedText";
import {translate} from "../../../../util/localizationUtil";
import {WithOnAlertProp, WithResetAlertsProp} from "../../../../type/component/WithAlertProps";

export type ValidationProps =
	WithOnAlertProp &
	WithResetAlertsProp & {
	onApiKeyConfirmed: (apiKey: string, sessionRef: SessionRef) => any;
	onAdminSearchRequested: () => any;
	onAdminSessionListRequested: () => any;
	auth: Auth | null;
	lang: string;
};

function getApiKeyFromUrl(): string {
	const up = new URLSearchParams(document.location.search);
	return up.get('apiKey') || '';
}

function IntroForm(
	{
		auth,
		lang,
		onApiKeyConfirmed,
		onResetAlerts,
		onAdminSearchRequested,
		onAdminSessionListRequested
	}: ValidationProps) {
	const [pluginApiKey, setPluginApiKey] = useState<string>(getApiKeyFromUrl());
	const [isValidationVisible, setIsValidationVisible] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [validationMessage, setValidationMessage] = useState<string>('');

	const startSession = (force: boolean = false) => {
		setIsValidationVisible(false);
		setIsLoading(true);
		startValidationSession(pluginApiKey, 'PlatformSpecific', force)
			.then(
				(ref: SessionRef) => {
					setIsLoading(false);
					onResetAlerts();
					onApiKeyConfirmed(pluginApiKey, ref);
				},
				(err: Error) => {
					setIsLoading(false);
					setValidationMessage(translate((err.cause === 404) ? 'Plugin not found!' : err.message, lang));
					setIsValidationVisible(true);
				}
			);
	};

	const formSubmitted = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (isEmptyString(pluginApiKey)) {
			setValidationMessage('Enter valid API key!')
			setIsValidationVisible(true);
			return;
		}
		setIsValidationVisible(false);
		startSession();
	};

	return (
		<div className="py-2 py-md-4">
			<Row>
				<Col md>
					<h2 className="pb-2 pb-md-4"><LocalizedText message="Welcome to Incomaker" lang={lang}/></h2>
					<p>
						<LocalizedText message="--welcome-1" lang={lang}/>
					</p>
					<p>
						<LocalizedText message="--welcome-2" lang={lang} containsHtml={true}/>
					</p>
					<p>
						<LocalizedText message="--welcome-3" lang={lang} containsHtml={true}/>
					</p>

				</Col>
				<Col md>
					<h2 className="pb-2 pb-md-4"><LocalizedText message="Plugin Validation" lang={lang}/></h2>
					<Form onSubmit={formSubmitted}>
						<Form.Group className="position-relative">
							<Form.Label>
								<strong><LocalizedText message="Plugin API Key" lang={lang}/></strong>:
							</Form.Label>
							<Form.Control
								type="text"
								name="api_key"
								value={pluginApiKey}
								onChange={(e) => setPluginApiKey(e.target.value)}
								isInvalid={isValidationVisible}
							/>
							<Form.Control.Feedback type="invalid">
								<LocalizedText message={validationMessage} lang={lang}/>
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Text>
							<LocalizedText message="--api-key-hint" lang={lang} containsHtml={true}/>
						</Form.Text>
						<Form.Group className="my-2 my-md-4">
							<div className="d-flex justify-content-start align-items-center">
								<Button variant="primary" type="submit" className="d-flex me-4 align-items-center">
									<LocalizedText message="Validate" lang={lang}/>
									<BsArrowRightCircleFill size="1.5em" className="ms-2"/>
								</Button>
								{
									isLoading && <Loading small={false} variant="primary"/>
								}
							</div>
						</Form.Group>
						{
							auth && (
								<Form.Group className="my-2 my-md-4">
									<Form.Label>
										<strong>Admin</strong>:
									</Form.Label>
									<Stack direction="horizontal" gap={2} className="flex-wrap">
										<Button
											onClick={onAdminSearchRequested}
											variant="primary"
											className="d-flex align-items-center"
										>
											<BsSearch size="1.5em" className="me-2"/>
											Search plugin...
										</Button>
										<Button
											onClick={onAdminSessionListRequested}
											variant="primary"
											className="d-flex align-items-center"
										>
											Sessions...
										</Button>
									</Stack>
								</Form.Group>
							)
						}
					</Form>
				</Col>
			</Row>
		</div>
	);
}

export default IntroForm;
