import React from 'react';
import {formatDateForHumans} from "../../util/dateUtil";

export type LocalizeDateProps = {
	date: Date | null | undefined;
	lang?: string;
};

function LocalizedDate({date, lang}: LocalizeDateProps) {
	return <span>{formatDateForHumans(date, lang)}</span>;
}

export default LocalizedDate;
