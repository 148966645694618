import {getLanguage} from "./sessionUtil";

export function formatNumber(n: number, digits = 2) {
	const s = String(n);
	return s.padStart(digits, '0');
}

export function formatDateForHumans(d: Date | string | null | undefined, lang?: string): string {
	if (!d) return "";
	if (!lang) lang = getLanguage();
	if (typeof d === 'string') {
		return formatDateForHumans(new Date(d), lang);
	}
	return `${d.toLocaleDateString(lang, {dateStyle: 'short'})} ${d.toLocaleTimeString(lang)}`;
}

export function getDuration(start: Date, finish: Date | undefined | null): number {
	if (!finish) finish = new Date();
	return new Date(finish).getTime() - new Date(start).getTime();
}

