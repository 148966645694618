import React from 'react';
import {Container, Spinner, Stack} from "react-bootstrap";
import {FaBeerMugEmpty} from "react-icons/fa6";

export type FooterProps = {
	version: string | boolean | null;
};

function Footer({version}: FooterProps) {
	return (
		<footer>
			<Container fluid="sm">
				<Stack direction="horizontal" className="mt-3 p-3 border-top d-flex flex-wrap">
					<div>
						<span>v. </span>
						{
							version ?
								<span>{String(version)}</span>
								:
								version === null ?
									<Spinner animation="border" role="status" size="sm">
										<span className="visually-hidden">loading...</span>
									</Spinner>
									:
									<span className="text-danger">Error</span>
						}
					</div>

					<div className="d-flex justify-content-end align-items-center m-auto">
						<FaBeerMugEmpty className="me-2"/>
						<strong>
							P I V O
						</strong>
					</div>

					<div>
						<div className="d-flex justify-content-end align-items-center">
							&copy; Incomaker {new Date().getFullYear()}
						</div>
					</div>
				</Stack>
			</Container>
		</footer>
	);
}

export default Footer;
