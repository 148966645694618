import React from 'react';
import {Button, Toast} from "react-bootstrap";
import {BsFillSquareFill} from 'react-icons/bs';
import {UserAlert, UserAlerts} from "../../type/UserAlert";
import {WithRemoveAlertProp, WithResetAlertsProp} from "../../type/component/WithAlertProps";

export type AlertBoxProps =
	WithRemoveAlertProp &
	WithResetAlertsProp & {
	alerts: UserAlerts;
};

function AlertBox({alerts, onRemoveAlert, onResetAlerts}: AlertBoxProps) {
	return (
		<div className="alert-box">
			{
				alerts.map(
					(alert: UserAlert, index: number) => {
						return <Toast
							key={index}
							bg={alert.type}
							color={alert.type}
							onClose={() => onRemoveAlert(alert)}
							className="m-1"
						>
							<Toast.Header className="justify-content-between">
								<BsFillSquareFill/>
								<strong className="ms-2 me-auto">{alert.title}</strong>
							</Toast.Header>
							<Toast.Body>{alert.message}</Toast.Body>
						</Toast>
					}
				)
			}
			{
				alerts.length > 0 &&
				<div className="d-flex justify-content-center p-2">
					<Button onClick={onResetAlerts} size="sm" variant="dark">Clear All</Button>
				</div>
			}
		</div>
	);
}

export default AlertBox;
