import React, {PropsWithChildren} from 'react';
import {Button, Form, Stack, Table} from "react-bootstrap";
import {BsChevronBarLeft, BsChevronBarRight, BsChevronLeft, BsChevronRight, BsFillCaretDownFill, BsFillCaretUpFill} from 'react-icons/bs';
import {clone} from "../../util/objectUtil";
import {Paging} from "../../type/Paging";
import {TableHeader} from "../../type/component/TableHeader";
import {isEmptyString} from "../../util/stringUtil";

const HEADER_CLASS = "p-0";
const HEADER_INNER_CLASS = "bg-blue text-light border-light rounded text-nowrap";

export type AdvancedTableProps = {
	header: TableHeader;
	paging: Paging;
	size?: string;
	totalPages: number;
	totalItems: number;
	onPagingChanged: (p: Paging) => any;
};

function AdvancedTable(
	{
		header,
		size,
		children,
		paging,
		totalPages,
		totalItems,
		onPagingChanged
	}: PropsWithChildren<AdvancedTableProps>) {

	const sortingChanged = (e: React.MouseEvent<HTMLTableCellElement>, fieldName: string) => {
		let field = paging.sorting.find((f) => f.name === fieldName);
		const isSoleField = field && paging.sorting.length === 1;
		if (!(e.ctrlKey || isSoleField)) {
			paging.sorting = [];
			field = undefined;
		}
		if (field) {
			field.desc = !field.desc;
		} else {
			paging.sorting.push({name: fieldName});
		}
		onPagingChanged(clone(paging));
	}

	const pageChanged = (page: number) => {
		paging.page = page;
		onPagingChanged(clone(paging));
	}

	const pagination =
		<div className="d-flex mb-1 justify-content-between align-items-center">
			<Button
				onClick={() => pageChanged(0)}
				disabled={paging.page === 0}
				className="me-1 py-2 d-flex "
			>
				<BsChevronBarLeft/>
			</Button>
			<Button
				onClick={() => pageChanged(paging.page - 1)}
				disabled={paging.page === 0}
				className="py-2 d-flex "
			>
				<BsChevronLeft/>
			</Button>
			<div className="text-nowrap px-3">{totalPages === 0 ? 0 : paging.page + 1} / {totalPages}</div>
			<Form.Range
				min={1}
				max={totalPages}
				value={paging.page + 1}
				onChange={(e) => pageChanged(Number(e.target.value) - 1)}
				disabled={totalPages === 0}
			/>
			<div className="text-dark px-3">{totalItems}</div>
			<Button
				onClick={() => pageChanged(paging.page + 1)}
				disabled={totalPages === 0 || paging.page === totalPages - 1}
				className="py-2 d-flex"
			>
				<BsChevronRight/>
			</Button>
			<Button
				onClick={() => pageChanged(totalPages - 1)}
				disabled={totalPages === 0 || paging.page === totalPages - 1}
				className="ms-1 py-2 d-flex"
			>
				<BsChevronBarRight/>
			</Button>
		</div>;

	const sizeCls = size ? `advanced-table-${size}` : '';

	return (
		<div className={`advanced-table ${sizeCls}`}>
			{pagination}
			<Table responsive striped hover size={size || ""}>
				<thead>
					<tr>
						{
							header.map(
								(h, index) => {
									if (h.name === '') {
										return <th key={index} className={HEADER_CLASS}>
											<div className={HEADER_INNER_CLASS} key={index}>
												<div>{isEmptyString(h.label) ? <span>&nbsp;</span> : h.label}</div>
											</div>
										</th>
									}
									const field = paging.sorting.find((s) => s.name === h.name);
									return (
										<th
											key={index}
											className={HEADER_CLASS}
											role="button"
											onClick={(e: React.MouseEvent<HTMLTableCellElement>) => sortingChanged(e, h.name)}
										>
											<Stack direction="horizontal" className={HEADER_INNER_CLASS}>
												<div>{h.label}</div>
												{
													field ? field.desc ? <BsFillCaretDownFill/> :
														<BsFillCaretUpFill/> : <></>
												}
											</Stack>
										</th>
									)
								}
							)
						}
					</tr>
				</thead>
				<tbody>
					{children}
				</tbody>
			</Table>
			{totalPages > 1 && pagination}
		</div>
	);
}

export default AdvancedTable;
