import React, {FormEvent, useState} from "react";
import {Button, Form, Stack} from "react-bootstrap";
import {setAuthentication} from "../../../util/sessionUtil";
import {WithAuth} from "../../../type/component/WithAuth";
import {WithOnAlertProp, WithResetAlertsProp} from "../../../type/component/WithAlertProps";
import {verifyAdminAuth} from "../../../util/restUtil";
import Loading from "../../controls/Loading";
import {WithAdminScreenSelection} from "../../../type/component/WithAdminActions";
import {ScreenSelection} from "../../../type/ScreenSelection";
import {BsBoxSeamFill, BsJournals, BsPlugin} from "react-icons/bs";

export type AdminPanelProps =
	WithAuth &
	WithAdminScreenSelection &
	WithOnAlertProp &
	WithResetAlertsProp & {
	onConfirmed: () => any;
	onCancelled: () => any;
};

function AdminPanel({auth, onConfirmed, onCancelled, onScreenSelectionChanged}: AdminPanelProps) {
	const [apiKey, setApiKey] = useState<string>(auth ? auth.apiKey : '');
	const [validationMessage, setValidationMessage] = useState<string | null>(null);
	const [verifying, setVerifying] = useState<boolean>(false);

	const logIn = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setValidationMessage(null);
		setVerifying(true);
		setAuthentication({apiKey: apiKey});
		verifyAdminAuth()
			.then(
				(verified: boolean) => {
					setVerifying(false);
					if (verified) {
						onConfirmed();
					} else {
						setValidationMessage('Invalid API key!');
						setAuthentication(null);
					}
				}
			);
	};

	const logOut = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setAuthentication(null);
		onConfirmed();
	};

	const changeScreen = (screen: ScreenSelection) => {
		onCancelled();
		onScreenSelectionChanged(screen);
	}

	const notLoggedIn = () => <>
		<Form onSubmit={logIn}>
			<Form.Group className="mb-3">
				<Form.Text>
					This section is for Incomaker administrators only! If you are our customer and you wish to check status of
					integration for your plugin, just use your API key on the main page.
				</Form.Text>
			</Form.Group>
			<Form.Group className="mb-3 position-relative">
				<Form.Label>Secret Administrator Key</Form.Label>
				<Form.Control
					type="password"
					value={apiKey}
					onChange={(e) => setApiKey(e.target.value)}
					isInvalid={validationMessage !== null}
				/>
				<Form.Control.Feedback type="invalid">
					{validationMessage}
				</Form.Control.Feedback>
			</Form.Group>
			<Form.Group className="d-flex flex-row gap-2">
				<Button variant="primary" type="submit">
					Log In
				</Button>
				{
					verifying && <Loading/>
				}
				<Button variant="link" type="button" onClick={onCancelled}>
					Close
				</Button>
			</Form.Group>
		</Form>
	</>;

	const adminLoggedIn = () => <>
		<Form onSubmit={logOut}>
			<hr/>
			<Form.Group className="mb-3">
				<Form.Text>
					You are now logged in as an administrator.
				</Form.Text>
			</Form.Group>
			<Form.Group className="my-2 my-md-4">
				<Form.Label>
					<strong>Public Section</strong>
				</Form.Label>
				<Stack direction="vertical" gap={2} className="flex-wrap">
					<Button
						onClick={() => changeScreen(ScreenSelection.Main)}
						variant="link"
						className="d-flex align-items-center"
					>
						Intro - enter API key
					</Button>
				</Stack>
			</Form.Group>
			<Form.Group className="my-2 my-md-4">
				<Form.Label>
					<strong>Admin Section</strong>
				</Form.Label>
				<Stack direction="vertical" gap={2} className="flex-wrap">
					<Button
						onClick={() => changeScreen(ScreenSelection.AdminPlatforms)}
						variant="link"
						className="d-flex align-items-center gap-3"
					>
						<BsBoxSeamFill/>
						Platforms
					</Button>
					<Button
						onClick={() => changeScreen(ScreenSelection.AdminPlugins)}
						variant="link"
						className="d-flex align-items-center gap-3"
					>
						<BsPlugin/>
						Plugins
					</Button>
					<Button
						onClick={() => changeScreen(ScreenSelection.AdminFeedBrowser)}
						variant="link"
						className="d-flex align-items-center gap-3"
					>
						<BsJournals/>
						Feed Browser
					</Button>
				</Stack>
			</Form.Group>
			<hr/>
			<Form.Group className="my-2 my-md-4">
				<Stack direction="horizontal">
					<Button variant="dark" type="submit">
						Log Out
					</Button>
					<Button variant="link" type="button" onClick={onCancelled}>
						Close
					</Button>
				</Stack>
			</Form.Group>
		</Form>
	</>;

	return (
		<div className="admin-panel">
			{
				auth ? adminLoggedIn() : notLoggedIn()
			}
		</div>
	);
}

export default AdminPanel;
