import React from 'react';
import {Result} from "../../../../type/data/Result";
import ResultControl from "./ResultControl";
import {Accordion, Card, Stack} from "react-bootstrap";
import {BsMortarboardFill, BsQuestionCircleFill} from 'react-icons/bs';

export type GroupResultProps = {
	result: Result;
};

function GroupResultControl({result}: GroupResultProps) {
	return (
		<div>
			<h3 className="my-3">
				{result.label}
			</h3>
			<div>
				{
					result.description && (
						<Card>
							<Stack direction="horizontal" className="align-items-stretch">
								<div className="d-flex p-2 ms-1 border-end align-items-center">
									<BsQuestionCircleFill color="var(--blue)" size={15} opacity={0.8}/>
								</div>
								<div className="p-2 text-wrap">
									{result.description}
								</div>
							</Stack>
						</Card>
					)
				}
				{
					result.suggestion && (
						<Card>
							<Stack direction="horizontal" className="align-items-stretch">
								<div className="d-flex p-2 ms-1 border-end align-items-center">
									<BsMortarboardFill color="var(--blue)" size={15} opacity={0.8}/>
								</div>
								<div className="p-2">
									{result.suggestion}
								</div>
							</Stack>
						</Card>
					)
				}
				<Accordion>
					{
						result.children &&
						result.children.map(
							(r, i) =>
								<ResultControl
									key={i}
									accordionKey={String(i)}
									result={r}
								/>
						)
					}
				</Accordion>
			</div>
		</div>
	);
}

export default GroupResultControl;
