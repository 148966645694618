import React, {useState} from 'react';
import {SessionRef} from "../../../type/data/SessionRef";
import {WithOnAlertProp, WithResetAlertsProp} from "../../../type/component/WithAlertProps";
import IntroForm from "./intro/IntroForm";
import SessionControl from "./session/SessionControl";
import PluginSearchModal from "../admin/PluginSearchModal";
import {startValidationSession} from "../../../util/restUtil";
import SessionsListModal from "./SessionsListModal";
import {WithSessionChange} from "../../../type/component/WithSession";
import {WithGoHome} from "../../../type/component/WithGoHome";
import {WithAuth} from "../../../type/component/WithAuth";
import {WithLang} from "../../../type/component/WithLang";
import {Container} from "react-bootstrap";

export type MainScreenProps =
	WithAuth &
	WithLang &
	WithGoHome &
	WithSessionChange &
	WithOnAlertProp &
	WithResetAlertsProp & {
	sessionRef?: SessionRef | null;
};

function MainScreen({auth, lang, onAlert, onResetAlerts, sessionRef, onChangeSession, onGoHome}: MainScreenProps) {
	const [lastApiKey, setLastApiKey] = useState<string | null>(null);
	const [showSearchForm, setShowSearchForm] = useState<boolean>(false);
	const [showSessionsList, setShowSessionsList] = useState<boolean>(false);

	const pluginSearchConfirmed = (pluginApiKey: string) => {
		setShowSearchForm(false);
		setLastApiKey(pluginApiKey);
		startValidationSession(pluginApiKey, 'PlatformSpecific', false)
			.then(
				(ref: SessionRef) => {
					onResetAlerts();
					onChangeSession(ref);
				},
				(err) => {
					onAlert({type: 'danger', title: 'Error', message: String(err)});
				}
			);
	}
	const sessionsListConfirmed = (sessionRef: SessionRef) => {
		setShowSessionsList(false);
		onChangeSession(sessionRef);
	}

	const apiKeyConfirmed = (apiKey: string, sessionRef: SessionRef) => {
		setLastApiKey(apiKey);
		onChangeSession(sessionRef);
	}

	const restartExpiredSession = () => {
		pluginSearchConfirmed(String(lastApiKey));
	}

	return (
		<Container fluid="sm">
			<div className="py-2">
				{
					sessionRef ?
						<SessionControl
							auth={auth}
							lang={lang}
							onAlert={onAlert}
							onResetAlerts={onResetAlerts}
							sessionRef={sessionRef}
							onExpiredSessionRestartRequested={restartExpiredSession}
							onNavigateBack={onGoHome}
							onAdminSearchRequested={() => setShowSearchForm(true)}
							onAdminSessionListRequested={() => setShowSessionsList(true)}
						/>
						:
						<IntroForm
							auth={auth}
							lang={lang}
							onApiKeyConfirmed={apiKeyConfirmed}
							onAlert={onAlert}
							onResetAlerts={onResetAlerts}
							onAdminSearchRequested={() => setShowSearchForm(true)}
							onAdminSessionListRequested={() => setShowSessionsList(true)}
						/>
				}
				{
					showSearchForm ?
						<PluginSearchModal
							onAlert={onAlert}
							onCancelled={() => setShowSearchForm(false)}
							onConfirmed={pluginSearchConfirmed}/>
						: <></>
				}
				{
					showSessionsList ?
						<SessionsListModal
							onAlert={onAlert}
							onCancelled={() => setShowSessionsList(false)}
							onConfirmed={sessionsListConfirmed}/>
						: <></>
				}
			</div>
		</Container>
	);
}

export default MainScreen;
