import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, Modal, Row, Stack} from 'react-bootstrap';
import {clone, isEmpty} from "../../../../util/objectUtil";
import {Plugin} from "../../../../type/data/Plugin";
import {ModalFormProps} from "../../../../type/component/FormProps";
import {WithOnAlertProp} from "../../../../type/component/WithAlertProps";
import {
	loadDataSourceTypeCategoryOptions,
	loadDataSourceTypeContactOptions,
	loadDataSourceTypeCouponOptions,
	loadDataSourceTypeCouponTemplateOptions,
	loadDataSourceTypeInfoOptions,
	loadDataSourceTypeOrderOptions,
	loadDataSourceTypeProductOptions,
	loadPlatformsAll,
	loadPluginStatusOptions
} from "../../../../util/restUtil";
import {BsSignStopFill} from 'react-icons/bs';
import {Platform, PlatformCollection} from "../../../../type/data/Platform";
import {isEmptyNumber} from "../../../../util/numberUtil";
import {formatJsonForHumans} from "../../../../util/jsonUtil";
import {formatDateForHumans} from "../../../../util/dateUtil";
import {isEmptyString} from "../../../../util/stringUtil";

const LABEL_COL = 2;
const VALUE_COL = 10;
const LABEL_COL_IMPORTS = 3;
export type PluginFormProps = ModalFormProps<Plugin> & WithOnAlertProp & {}

function PluginForm({data, onSave, onClose, onAlert}: PluginFormProps) {
	const [formData, setFormData] = useState<Plugin>(clone(data));
	const [isValid, setIsValid] = useState<boolean>(true);
	const [statusOptions, setStatusOptions] = useState<string[] | null>(null);
	const [platformOptions, setPlatformOptions] = useState<PlatformCollection | null>(null);
	const [contactFormats, setContactFormats] = useState<string[] | null>(null);
	const [categoryFormats, setCategoryFormats] = useState<string[] | null>(null);
	const [productFormats, setProductFormats] = useState<string[] | null>(null);
	const [orderFormats, setOrderFormats] = useState<string[] | null>(null);
	const [couponTemplateFormats, setCouponTemplateFormats] = useState<string[] | null>(null);
	const [couponFormats, setCouponFormats] = useState<string[] | null>(null);
	const [infoFormats, setInfoFormats] = useState<string[] | null>(null);

	if (typeof formData.params === 'object') {
		formData.params = formatJsonForHumans(formData.params);
	}

	const errorAlert = useCallback(
		(err: any) => onAlert({type: 'danger', title: 'Error', message: String(err)}),
		[onAlert]
	);

	const loadOptions = () => {
		loadPluginStatusOptions()
			.then(
				setStatusOptions,
				errorAlert
			);
		loadPlatformsAll()
			.then(
				setPlatformOptions,
				errorAlert
			);
		loadDataSourceTypeContactOptions()
			.then(
				(formats) => setContactFormats(formats),
				errorAlert
			);
		loadDataSourceTypeCategoryOptions()
			.then(
				(formats) => setCategoryFormats(formats),
				errorAlert
			);
		loadDataSourceTypeProductOptions()
			.then(
				(formats) => setProductFormats(formats),
				errorAlert
			);
		loadDataSourceTypeOrderOptions()
			.then(
				(formats) => setOrderFormats(formats),
				errorAlert
			);
		loadDataSourceTypeCouponTemplateOptions()
			.then(
				(formats) => setCouponTemplateFormats(formats),
				errorAlert
			);
		loadDataSourceTypeCouponOptions()
			.then(
				(formats) => setCouponFormats(formats),
				errorAlert
			);
		loadDataSourceTypeInfoOptions()
			.then(
				(formats) => setInfoFormats(formats),
				errorAlert
			);
	}

	useEffect(loadOptions, []);

	const findPlatform = (id: number): Platform | undefined => {
		if (platformOptions === null) return undefined;
		return platformOptions.find((p) => p.id === id);
	}

	const validate = () => {
		let valid = true;
		if (isEmpty(formData.account) || isEmptyNumber(formData.account?.id)) {
			valid = false;
		}
		setIsValid(valid);
	};

	useEffect(validate, [formData]);

	const updateField = (action: () => any) => {
		action();
		setFormData(clone(formData));
	}

	const save = () => {
		if (typeof formData.params === 'string') {
			try {
				formData.params = isEmptyString(formData.params) ? null : JSON.parse(formData.params);
			} catch (e) {
				onAlert({message: `Cannot parse JSON: ${e}`, title: 'Error', type: 'danger'});
				return;
			}
		}
		onSave(formData);
	}

	return (
		<Modal show={true} onHide={onClose} size="xl" centered>
			<Modal.Header closeButton>
				<Modal.Title>Plugin {formData.id}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={save}>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL}>ID</Form.Label>
						<Col sm={VALUE_COL}>
							<Form.Control
								plaintext
								readOnly
								value={String(formData.id || '')}>
							</Form.Control>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL} for="accountId">Account ID</Form.Label>
						<Col sm={VALUE_COL}>
							<Form.Control
								id="accountId"
								plaintext
								readOnly
								value={String(formData.account?.id)}
							></Form.Control>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL} for="pluginStatus">Status</Form.Label>
						<Col>
							<Form.Select
								id="pluginStatus"
								value={formData.pluginStatus}
								onChange={(e) => updateField(() => formData.pluginStatus = e.target.value)}
								isInvalid={formData.pluginStatus === undefined || formData.pluginStatus === null}
							>
								{
									statusOptions ?
										<>
											{statusOptions.map((f) => <option key={f} value={f}>{f}</option>)}
										</> : <option>loading...</option>
								}
							</Form.Select>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL} for="platform">Platform</Form.Label>
						<Col>
							<Form.Select
								id="platform"
								value={Number(formData.platform?.id)}
								onChange={(e) => updateField(() => formData.platform = findPlatform(Number(e.target.value)))}
							>
								<option></option>
								{
									platformOptions ?
										<>
											{platformOptions.map((p) => <option key={p.id} value={String(p.id)}>{p.name}</option>)}
										</> : <option>loading...</option>
								}
							</Form.Select>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL} for="palp">API Key</Form.Label>
						<Col>
							<Form.Control
								id="apiKey"
								type="text"
								value={formData.apiKey}
								onChange={(e) => updateField(() => formData.apiKey = e.target.value)}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL} for="palp">URL</Form.Label>
						<Col>
							<Form.Control
								id="url"
								type="text"
								value={formData.url}
								onChange={(e) => updateField(() => formData.url = e.target.value)}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL} for="palp">Import Base URL</Form.Label>
						<Col>
							<Form.Control
								id="palp"
								type="text"
								value={formData.palpUrl}
								onChange={(e) => updateField(() => formData.palpUrl = e.target.value)}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL} for="params">Params</Form.Label>
						<Col>
							<Form.Control
								id="palp"
								as="textarea"
								rows={4}
								value={formData.params}
								onChange={(e) => updateField(() => formData.params = e.target.value)}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL} for="importsDisabled">Imports Disabled</Form.Label>
						<Col sm={VALUE_COL} className="d-flex align-items-center">
							<Form.Check
								id="importsDisabled"
								type="switch"
								checked={formData.importsDisabled}
								onChange={(e) => updateField(() => formData.importsDisabled = e.target.checked)}
								className="me-2"
							/>
							{
								formData.importsDisabled &&
								<BsSignStopFill color="red" size="25px"/>
							}
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL}></Form.Label>
						<Col>
							<Row>
								<Form.Label
									column
									sm={LABEL_COL_IMPORTS}
									for="dataSourceTypeInfo"
									className={formData.importsDisabled ? 'text-muted' : ''}
								>
									Info
								</Form.Label>
								<Col>
									<Form.Select
										id="dataSourceTypeInfo"
										value={String(formData.dataSourceTypeInfo)}
										onChange={(e) => updateField(() => formData.dataSourceTypeInfo = e.target.value === '' ? null : e.target.value)}
										disabled={formData.importsDisabled}
									>
										{
											infoFormats ?
												<>
													<option value="">
														(default) {formData?.platform?.dataSourceTypeInfo}
													</option>
													{infoFormats.map((f) => <option key={f} value={f}>{f}</option>)}
												</> : <option>loading...</option>
										}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Form.Label
									column
									sm={LABEL_COL_IMPORTS}
									for="dataSourceTypeContact"
									className={formData.importsDisabled ? 'text-muted' : ''}
								>
									Contacts
								</Form.Label>
								<Col>
									<Form.Select
										id="dataSourceTypeContact"
										value={String(formData.dataSourceTypeContact)}
										onChange={(e) => updateField(() => formData.dataSourceTypeContact = e.target.value === '' ? null : e.target.value)}
										disabled={formData.importsDisabled}
									>
										{
											contactFormats ?
												<>
													<option value="">
														(default) {formData?.platform?.dataSourceTypeContact}
													</option>
													{contactFormats.map((f) => <option key={f} value={f}>{f}</option>)}
												</> : <option>loading...</option>
										}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Form.Label
									column
									sm={LABEL_COL_IMPORTS}
									for="dataSourceTypeCategory"
									className={formData.importsDisabled ? 'text-muted' : ''}
								>
									Categories
								</Form.Label>
								<Col>
									<Form.Select
										id="dataSourceTypeCategory"
										value={String(formData.dataSourceTypeCategory)}
										onChange={(e) => updateField(() => formData.dataSourceTypeCategory = e.target.value === '' ? null : e.target.value)}
										disabled={formData.importsDisabled}
									>
										{
											categoryFormats ?
												<>
													<option value="">
														(default) {formData?.platform?.dataSourceTypeCategory}
													</option>
													{categoryFormats.map((f) => <option key={f} value={f}>{f}</option>)}
												</> : <option>loading...</option>
										}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Form.Label
									column
									sm={LABEL_COL_IMPORTS}
									for="dataSourceTypeProduct"
									className={formData.importsDisabled ? 'text-muted' : ''}
								>
									Products
								</Form.Label>
								<Col>
									<Form.Select
										id="dataSourceTypeProduct"
										value={String(formData.dataSourceTypeProduct)}
										onChange={(e) => updateField(() => formData.dataSourceTypeProduct = e.target.value === '' ? null : e.target.value)}
										disabled={formData.importsDisabled}
									>
										{
											productFormats ?
												<>
													<option value="">
														(default) {formData?.platform?.dataSourceTypeProduct}
													</option>
													{productFormats.map((f) => <option key={f} value={f}>{f}</option>)}
												</> : <option>loading...</option>
										}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Col sm={LABEL_COL_IMPORTS}></Col>
								<Col>
									<Stack direction="horizontal" className="justify-content-between pb-1">
										<Form.Label
											column
											for="productFullUpdateRequired"
											className={formData.importsDisabled ? 'text-muted' : ''}
										>
											Full Update
										</Form.Label>
										<Col>
											<Form.Select
												id="productFullUpdateRequired"
												value={isEmpty(formData.productFullUpdateRequired) ? '' : (formData.productFullUpdateRequired === true ? '1' : '0')}
												onChange={
													(e) => updateField(
														() => formData.productFullUpdateRequired = e.target.value === '' ? null : e.target.value === '1')
												}
												disabled={formData.importsDisabled}
											>
												<option value="">
													(default) {formData?.platform?.productFullUpdateRequired ? 'Required' : 'Unnecessary'}
												</option>
												<option value="1">
													Required
												</option>
												<option value="0">
													Unnecessary
												</option>
											</Form.Select>
										</Col>
									</Stack>
								</Col>
							</Row>
							<Row>
								<Col sm={LABEL_COL_IMPORTS}></Col>
								<Col>
									<Stack direction="horizontal" className="justify-content-between pb-1">
										<Form.Label
											column
											for="manualProductDeactivationRequired"
											className={formData.importsDisabled ? 'text-muted' : ''}
										>
											Deactivation
										</Form.Label>
										<Col>
											<Form.Select
												id="manualProductDeactivationRequired"
												value={isEmpty(formData.manualProductDeactivationRequired) ? '' : (formData.manualProductDeactivationRequired === true ? '1' : '0')}
												onChange={
													(e) => updateField(
														() => formData.manualProductDeactivationRequired = e.target.value === '' ? null : e.target.value === '1')
												}
												disabled={formData.importsDisabled}
											>
												<option value="">
													(default) {formData?.platform?.manualProductDeactivationRequired ? 'Required' : 'Unnecessary'}
												</option>
												<option value="1">
													Required
												</option>
												<option value="0">
													Unnecessary
												</option>
											</Form.Select>
										</Col>
									</Stack>
								</Col>
							</Row>
							<Row>
								<Form.Label
									column
									sm={LABEL_COL_IMPORTS}
									for="dataSourceTypeOrder"
									className={formData.importsDisabled ? 'text-muted' : ''}
								>
									Orders
								</Form.Label>
								<Col>
									<Form.Select
										id="dataSourceTypeOrder"
										value={String(formData.dataSourceTypeOrder)}
										onChange={(e) => updateField(() => formData.dataSourceTypeOrder = e.target.value === '' ? null : e.target.value)}
										disabled={formData.importsDisabled}
									>
										{
											orderFormats ?
												<>
													<option value="">
														(default) {formData?.platform?.dataSourceTypeOrder}
													</option>
													{orderFormats.map((f) => <option key={f} value={f}>{f}</option>)}
												</> : <option>loading...</option>
										}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Form.Label
									column
									sm={LABEL_COL_IMPORTS}
									for="dataSourceTypeCouponTemplate"
									className={formData.importsDisabled ? 'text-muted' : ''}
								>
									Coupon Templates
								</Form.Label>
								<Col>
									<Form.Select
										id="dataSourceTypeCouponTemplate"
										value={String(formData.dataSourceTypeCouponTemplate)}
										onChange={(e) => updateField(() => formData.dataSourceTypeCouponTemplate = e.target.value === '' ? null : e.target.value)}
										disabled={formData.importsDisabled}
									>
										{
											couponTemplateFormats ?
												<>
													<option value="">
														(default) {formData?.platform?.dataSourceTypeCouponTemplate}
													</option>
													{couponTemplateFormats.map((f) => <option key={f} value={f}>{f}</option>)}
												</> : <option>loading...</option>
										}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Form.Label
									column
									sm={LABEL_COL_IMPORTS}
									for="dataSourceTypeCoupon"
									className={formData.importsDisabled ? 'text-muted' : ''}
								>
									Coupons
								</Form.Label>
								<Col>
									<Form.Select
										id="dataSourceTypeCoupon"
										value={String(formData.dataSourceTypeCoupon)}
										onChange={(e) => updateField(() => formData.dataSourceTypeCoupon = e.target.value === '' ? null : e.target.value)}
										disabled={formData.importsDisabled}
									>
										{
											couponFormats ?
												<>
													<option value="">
														(default) {formData?.platform?.dataSourceTypeCoupon}
													</option>
													{couponFormats.map((f) => <option key={f} value={f}>{f}</option>)}
												</> : <option>loading...</option>
										}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Col sm={LABEL_COL_IMPORTS}></Col>
								<Col>
									<Stack direction="horizontal">
										<Form.Label
											column
											for="couponCreateDisabled"
											className={formData.importsDisabled ? 'text-muted' : ''}
										>
											Coupon Create Disabled
										</Form.Label>
										<Form.Check
											id="couponCreateDisabled"
											type="switch"
											checked={formData.couponCreateDisabled}
											onChange={(e) => updateField(() => formData.couponCreateDisabled = e.target.checked)}
											disabled={formData.importsDisabled}
										/>
										{
											formData.couponCreateDisabled &&
											<BsSignStopFill color="red" size="25px"/>
										}
									</Stack>
								</Col>
							</Row>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL} for="eventsSupported">Events Disabled</Form.Label>
						<Col sm={VALUE_COL} className="d-flex align-items-center">
							<Form.Check
								id="eventsSupported"
								type="switch"
								checked={formData.eventsDisabled}
								onChange={(e) => updateField(() => formData.eventsDisabled = e.target.checked)}
							/>
							{
								formData.eventsDisabled &&
								<BsSignStopFill color="red" size="25px"/>
							}
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL} for="trackingSupported">Tracking Disabled</Form.Label>
						<Col sm={VALUE_COL} className="d-flex align-items-center">
							<Form.Check
								id="trackingSupported"
								type="switch"
								checked={formData.trackingDisabled}
								onChange={(e) => updateField(() => formData.trackingDisabled = e.target.checked)}
							/>
							{
								formData.trackingDisabled &&
								<BsSignStopFill color="red" size="25px"/>
							}
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL} for="lastUpdate">Last Update</Form.Label>
						<Col sm={VALUE_COL} className="d-flex align-items-center">
							{
								formatDateForHumans(formData.lastUpdate)
							}
						</Col>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="link" onClick={onClose}>Close</Button>
				<Button variant="success" type="submit" onClick={save} disabled={!isValid}>Save</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default PluginForm;
