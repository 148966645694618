import {isEmptyString} from "./stringUtil";

export function formatJsonForHumans(val: string | object | null | undefined): string {
	if (val === null || val === undefined) return '';
	if (typeof val !== 'string') return formatJsonForHumans(JSON.stringify(val));
	if (!isJson(val)) return '';
	try {
		return JSON.stringify(JSON.parse(val), null, 4);
	} catch (e) {
		return val;
	}
}

export function isJson(str: string | null | undefined): boolean {
	if (isEmptyString(str)) return false;
	return (str?.startsWith('{') && str?.endsWith('}')) === true;
}
