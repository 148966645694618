import indent from "indent.js";
import {isEmptyString} from "./stringUtil";

export function formatHtmlForHumans(html) {
	if (isEmptyString(html)) return '';
	try {
		return indent.indent.html(html);
	} catch (e) {
		return html;
	}
}

export function isHtml(str) {
	if (isEmptyString(str)) return false;
	return str?.startsWith('<!DOCTYPE html>') || str?.startsWith('<html');
}
