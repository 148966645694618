import React from 'react';
import {Result} from "../../../../type/data/Result";
import ValidationState from "./ValidationState";
import {Accordion, Card, Stack} from "react-bootstrap";
import {hasContent} from "../../../../util/stringUtil";
import {BsMortarboardFill, BsQuestionCircleFill} from "react-icons/bs";
import SmartTextBox from "../../../controls/SmartTextBox";

export type ResultProps = {
	result: Result;
	accordionKey: string;
};

function ResultControl({result, accordionKey}: ResultProps) {

	const hasChildren = result.children && result.children.length > 0;
	const displayAccordion = hasChildren || hasContent(result.description) || hasContent(result.suggestion);

	const header = (
		<Stack direction="horizontal">
			<div className="pe-3">
				<ValidationState state={result.state}/>
			</div>
			{
				hasChildren ?
					<h4>{result.label}</h4>
					:
					<Stack direction="horizontal" gap={2} className="flex-wrap">
						<div className="text-wrap">
							{result.label}:
						</div>
						<div className="fw-bold text-wrap">
							{result.value}
						</div>
					</Stack>
			}
		</Stack>
	)

	return (
		<Accordion.Item eventKey={accordionKey}>
			<Accordion.Header as="div" className={displayAccordion ? "" : "empty-accordion"}>
				{header}
			</Accordion.Header>
			{
				displayAccordion && (
					<Accordion.Body className="d-grid gap-2">
						{
							result.description && (
								<Card>
									<Stack direction="horizontal" className="align-items-stretch">
										<div className="d-flex p-2 ms-1 border-end align-items-center">
											<BsQuestionCircleFill color="var(--blue)" size={15} opacity={0.8}/>
										</div>
										<div className="p-2 text-wrap overflow-hidden">
											<SmartTextBox text={result.description}/>
										</div>
									</Stack>
								</Card>
							)
						}
						{
							result.suggestion && (
								<Card>
									<Stack direction="horizontal" className="align-items-stretch">
										<div className="d-flex p-2 ms-1 border-end align-items-center">
											<BsMortarboardFill color="var(--blue)" size={15} opacity={0.8}/>
										</div>
										<div className="p-2">
											{result.suggestion}
										</div>
									</Stack>
								</Card>
							)
						}
						{
							hasChildren && (
								<Accordion>
									{
										// @ts-ignore
										result.children.map(
											(r, i) =>
												<ResultControl
													key={i}
													accordionKey={String(i)}
													result={r}
												/>
										)
									}
								</Accordion>
							)
						}
					</Accordion.Body>
				)
			}
		</Accordion.Item>
	);
}

export default ResultControl;
