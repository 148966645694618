import {Paging, PagingRequest} from "../Paging";
import {sortingToRequestFormat} from "../../util/pagingUtil";

export type FeedBrowserFilter = {
	pluginId?: string;
	entity?: string;
	format?: string;
	worker?: string;
	timeFrom?: Date;
	timeTo?: Date;
	search?: string;
};

export type FeedBrowserFilterPaging = {
	paging: Paging,
	filter: FeedBrowserFilter
};

export type FeedBrowserSearchRequest = FeedBrowserFilter & PagingRequest;

export function filterToSearchRequest(f: FeedBrowserFilterPaging): FeedBrowserSearchRequest {
	return {
		page: f.paging.page,
		size: f.paging.size,
		sorting: sortingToRequestFormat(f.paging.sorting),
		search: f.filter.search,
		pluginId: f.filter.pluginId,
		entity: f.filter.entity,
		format: f.filter.format,
		worker: f.filter.worker,
		timeFrom: f.filter.timeFrom,
		timeTo: f.filter.timeTo
	}
}
