import React, {useState} from 'react';
import {Result} from "../../../../type/data/Result";
import GroupResultControl from "./GroupResultControl";
import {getDuration} from "../../../../util/dateUtil";
import {BsArrowRepeat} from "react-icons/bs";
import {Button, Card, Col, Row} from "react-bootstrap";
import DurationControl from "../../../controls/DurationControl";
import ValidationState from "./ValidationState";
import LocalizedText from "../../../controls/LocalizedText";
import {WithLang} from "../../../../type/component/WithLang";
import LocalizedDate from "../../../controls/LocalizedDate";
import {WithAuth} from '../../../../type/component/WithAuth';
import PluginForm from "../../admin/plugin/PluginForm";
import {Plugin} from "../../../../type/data/Plugin";
import {WithOnAlertProp} from "../../../../type/component/WithAlertProps";
import {loadPluginOfSession, savePlugin} from "../../../../util/restUtil";
import {SessionRef} from "../../../../type/data/SessionRef";

export type SuitResultProps = WithLang & WithAuth & WithOnAlertProp & {
	onRestartSession: () => any;
	result: Result;
	sessionRef: SessionRef;
};

function SuitResultControl({auth, lang, result, sessionRef, onRestartSession, onAlert}: SuitResultProps) {
	const [form, setForm] = useState<Plugin | null>(null);

	const editPlugin = () => {
		loadPluginOfSession(sessionRef).then(setForm)
	}

	const savePluginForm = (data: Plugin) => {
		savePlugin(data)
			.then((savedData: Plugin) => {
				onAlert({type: 'success', title: 'Plugin Saved', message: "Plugin form was successfully saved. Restarting validation."});
				onRestartSession();
				setForm(null);
			})
			.catch((err) => {
				onAlert({type: 'danger', title: 'Error', message: String(err)});
			});
	}

	return (
		<div>
			<h2 className="text-center">
				{result.label}
			</h2>
			<Row className="justify-content-md-center">
				<Col md={8} lg={6}>
					<Card className="p-3 m-2">
						<Row>
							<Col sm={6}>
								<div className="d-flex align-items-center py-1">
									<div className="me-2">
										<LocalizedText message="Status" lang={lang}/>:
									</div>
									<ValidationState state={result.state}/>
									<div className="ms-2 text-bold">{result.state.label}</div>
								</div>
								<div className="py-1">
									<LocalizedText message="Processing Time" lang={lang}/>:&nbsp;
									<span className="text-bold">
										<DurationControl ms={getDuration(result.startTime, result.finishTime)}/>
									</span>
								</div>
							</Col>
							<Col>
								<div className="py-1">
									<LocalizedText message="Started" lang={lang}/>:&nbsp;
									<span className="text-bold">
										<LocalizedDate date={result.startTime} lang={lang}/>
									</span>
								</div>
								<div className="py-1">
									<LocalizedText message="Finished" lang={lang}/>:&nbsp;
									<span className="text-bold">
										<LocalizedDate date={result.finishTime} lang={lang}/>
									</span>
								</div>
							</Col>
						</Row>

						<Row>
							<Col>
								<div className="d-flex mt-2 justify-content-center">
									<Button
										variant="primary"
										type="submit"
										className="me-4"
										disabled={result.state.type === 'InProgress'}
										onClick={onRestartSession}
									>
										<BsArrowRepeat size="1.5em" className="me-2"/>
										<LocalizedText message="Restart Validation" lang={lang}/>
									</Button>
									{auth && <Button onClick={editPlugin}>Edit Plugin...</Button>}
									{
										form && <PluginForm
											data={form}
											onAlert={onAlert}
											onClose={() => setForm(null)}
											onSave={savePluginForm}
										/>
									}
								</div>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<div>
				{result.children && result.children.map((r: Result, i: number) => <GroupResultControl key={i} result={r}/>)}
			</div>
		</div>
	);
}

export default SuitResultControl;
