import React, {useCallback, useEffect, useState} from "react";
import {WithOnAlertProp} from "../../../../type/component/WithAlertProps";
import Loading from "../../../controls/Loading";
import AdvancedTable from "../../../controls/AdvancedTable";
import {Paging} from "../../../../type/Paging";
import {Plugin, PluginPage} from "../../../../type/data/Plugin";
import {loadPluginsPage, savePlugin} from "../../../../util/restUtil";
import {translate} from "../../../../util/localizationUtil";
import {WithLang} from "../../../../type/component/WithLang";
import PluginForm from "./PluginForm";
import {clone} from "../../../../util/objectUtil";
import {Button, Form, Stack} from "react-bootstrap";
import {BsSearch} from "react-icons/bs";
import TextInputWithReset from "../../../controls/TextInputWithReset";
import {FaBeerMugEmpty} from "react-icons/fa6";
import {isEmptyString} from "../../../../util/stringUtil";

const PAGE_SIZE: number = 10;

export type AdminPluginsProps =
	WithOnAlertProp &
	WithLang & {
	onPivoValidationRequested: (pluginApiKey: string) => any
};

function PluginTable({lang, onPivoValidationRequested, onAlert}: AdminPluginsProps) {
	const [loading, setLoading] = useState<boolean>(false);
	const [paging, setPaging] = useState<Paging>({page: 0, size: PAGE_SIZE, sorting: [{name: 'id', desc: true}]});
	const [search, setSearch] = useState<string>('');
	const [results, setResults] = useState<PluginPage | null>(null);
	const [form, setForm] = useState<Plugin | null>(null);

	const refreshResults = useCallback(
		() => {
			setLoading(true);
			loadPluginsPage(paging)
				.then(
					(results: PluginPage) => {
						setResults(results);
						setLoading(false);
					},
					(err) => {
						setLoading(false);
						onAlert({type: 'danger', title: 'Error', message: String(err)});
					}
				);
		},
		[paging, onAlert]
	);

	useEffect(refreshResults, [paging]);

	const updateSearch = (newSearch?: string) => {
		if (newSearch !== undefined) {
			setSearch(newSearch);
			paging.search = newSearch;
		} else {
			paging.search = search;
		}
		paging.page = 0;
		setPaging(clone(paging));
	}

	const formSubmitted = (e?: React.FormEvent<HTMLFormElement>) => {
		if (e) e.preventDefault();
		updateSearch();
	}

	const validationRequested = (e: React.MouseEvent<HTMLButtonElement>, plugin: Plugin) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		if (isEmptyString(plugin.apiKey)) {
			onAlert({'type': 'error', 'title': 'Error', 'message': 'Plugin has empty API key. Cannot validate!'});
			return;
		}
		onPivoValidationRequested(plugin.apiKey);
	}

	const saveData = (data: Plugin) => {
		setLoading(true);
		savePlugin(data)
			.then((savedData: Plugin) => {
				if (results?.content) {
					// update table data
					if (data.id === undefined) {
						results.content.unshift(savedData);
					} else {
						results.content = results.content.map(
							(p: Plugin): Plugin => {
								if (p.id === savedData.id) {
									return savedData;
								}
								return p;
							}
						);
					}
					setResults(clone(results));
				}

				setForm(null);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				onAlert({type: 'danger', title: 'Error', message: String(err)});
			});
	}

	const deleteData = (data: Plugin) => {
		onAlert({type: 'danger', title: 'Error', message: "Deleting plugins is not allowed in PIVO!"});
	}

	const header = [
		{name: 'id', label: 'ID'},
		{name: 'pluginStatus', label: 'Status'},
		{name: 'account.id', label: 'Account ID'},
		{name: 'account.name', label: 'Account Name'},
		{name: 'url', label: 'URL'},
		{name: 'platform.name', label: 'Platform'},
		{name: ''}
	];

	return (
		<div className="admin-panel p-4">
			<div className="d-flex flex-column align-items-center py-4">
				<h2 className="py-2 text-center">{translate('Plugins', lang)}</h2>
				<Form onSubmit={formSubmitted}>
					<Form.Group>
						<Stack direction="horizontal">
							<TextInputWithReset
								value={search}
								onChange={setSearch}
								onReset={() => updateSearch('')}
							/>
							<Button
								variant="primary"
								onClick={() => updateSearch()}
								className="ms-1"
							>
								<div>
									<BsSearch className="d-flex my-1"/>
								</div>
							</Button>
						</Stack>
					</Form.Group>
				</Form>
			</div>

			<div className="py-2">
				<AdvancedTable
					header={header}
					onPagingChanged={setPaging}
					totalPages={results ? results.totalPages : 0}
					totalItems={results ? results.totalElements : 0}
					paging={paging}
				>
					{
						results ?
							results.content.map(
								(result: Plugin, index: number) =>
									<tr
										key={index}
										className="cursor-pointer"
										onClick={() => setForm(result)}
									>
										<td>{result.id}</td>
										<td>{result.pluginStatus}</td>
										<td>{result.account?.id}</td>
										<td>{result.account?.name}</td>
										<td>{result.url}</td>
										<td>{result.platform?.name}</td>
										<td>
											<Stack direction="horizontal">
												{
													result.uuid !== undefined && (
														<Button onClick={(e) => validationRequested(e, result)}>
															<Stack direction="horizontal" gap={2}>
																<FaBeerMugEmpty/>
															</Stack>
														</Button>
													)
												}
											</Stack>
										</td>
									</tr>
							)
							: <></>
					}
				</AdvancedTable>
				{
					form && <PluginForm
						data={form}
						onAlert={onAlert}
						onClose={() => setForm(null)}
						onDelete={deleteData}
						onSave={saveData}
					/>
				}
				{
					loading && <div className="loading-overlay">
						<Loading/>
					</div>
				}
			</div>
		</div>
	);
}

export default PluginTable;
