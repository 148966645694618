import React from 'react';
import {Spinner} from "react-bootstrap";

export type LoadingProps = {
	message?: string;
	variant?: string;
	small?: boolean;
	size?: number;
};

function Loading({message, variant, size, small}: LoadingProps) {
	return (
		<div
			style={{width: size || 'auto', height: size || 'auto'}}
			className="loading d-flex flex-column justify-content-center align-items-center text-center"
		>
			<div className="d-flex gap-2">
				<Spinner animation="border" role="status" variant={variant || "dark"} size={small ? "sm" : undefined}>
					<span className="visually-hidden">{message || 'Loading...'}</span>
				</Spinner>
				{
					message &&
					<div>
						{message}
					</div>
				}
			</div>
		</div>
	);
}

export default Loading;
