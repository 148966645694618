import React, {useEffect, useState} from "react";
import {WithOnAlertProp} from "../../../../type/component/WithAlertProps";
import Loading from "../../../controls/Loading";
import AdvancedTable from "../../../controls/AdvancedTable";
import {Paging} from "../../../../type/Paging";
import {searchImportStash} from "../../../../util/restUtil";
import {translate} from "../../../../util/localizationUtil";
import {WithLang} from "../../../../type/component/WithLang";
import FeedBrowserDetail from "./FeedBrowserDetail";
import {clone} from "../../../../util/objectUtil";
import {Col, Row, Stack} from "react-bootstrap";
import {FeedBrowserFilter, FeedBrowserFilterPaging} from "../../../../type/data/FeedBrowserFilter";
import FeedBrowserFilterBar from "./FeedBrowserFilterBar";
import {formatDateForHumans} from "../../../../util/dateUtil";
import {formatByteSize} from "../../../../util/byteUtil";
import {ImportStashDocument, ImportStashDocumentPage} from "../../../../type/data/ImportStashDocument";

const PAGE_SIZE: number = 20;

export type FeedBrowserProps =
	WithOnAlertProp &
	WithLang & {};

function FeedBrowser({lang, onAlert}: FeedBrowserProps) {
	const [loading, setLoading] = useState<boolean>(false);

	const [filterPaging, setFilterPaging] = useState<FeedBrowserFilterPaging>({
		paging: {page: 0, size: PAGE_SIZE, sorting: [{name: 'timestamp', desc: true}]},
		filter: {}
	});

	const [results, setResults] = useState<ImportStashDocumentPage | null>(null);
	const [selected, setSelected] = useState<ImportStashDocument | null>(null);

	const refreshResults = () => {
		setLoading(true);
		searchImportStash(filterPaging)
			.then(
				(results: ImportStashDocumentPage) => {
					setResults(results);
					if (results.content.length > 0) {
						let newSelected = undefined;
						if (selected !== null) {
							newSelected = results.content.find((r) => r.id === selected.id);
						}
						if (newSelected === undefined) {
							newSelected = results.content[0];
						}
						setSelected(newSelected);
					} else {
						setSelected(null);
					}
					setLoading(false);
				},
				(err: any) => {
					setLoading(false);
					onAlert({type: 'danger', title: 'Error', message: String(err)});
				}
			);
	}

	useEffect(refreshResults, [filterPaging]);

	const updateFilter = (filter: FeedBrowserFilter) => {
		filterPaging.filter = clone(filter);
		filterPaging.paging.page = 0;
		updatePaging(filterPaging.paging);
	}

	const updatePaging = (paging: Paging) => {
		filterPaging.paging = clone(paging);
		setFilterPaging(clone(filterPaging));
	}

	const header = [
		{name: 'timestamp', label: 'Time'},
		{name: 'plugin', label: 'Plugin'},
		{name: 'entity', label: 'Entity'},
		{name: 'format', label: 'Format'},
		{name: 'worker', label: 'Worker'},
		{name: '_size', label: 'Size'}
	];

	return (
		<div className="px-4">
			<h2 className="py-2 text-center">{translate('Feed Browser', lang)}</h2>
			<FeedBrowserFilterBar filter={filterPaging.filter} onSubmit={updateFilter} onAlert={onAlert}/>
			<Row>
				<Col md={4} className="position-relative">
					<AdvancedTable
						header={header}
						onPagingChanged={updatePaging}
						totalPages={results ? results.totalPages : 0}
						totalItems={results ? results.totalElements : 0}
						paging={filterPaging.paging}
						size="sm"
					>
						{
							results ?
								results.content.map(
									(result: ImportStashDocument, index: number) =>
										<tr
											key={index}
											className={`cursor-pointer ${selected === result ? 'selected' : ''}`}
											onClick={() => setSelected(result)}
										>
											<td>{formatDateForHumans(result["@timestamp"])}</td>
											<td>{result.plugin}</td>
											<td>{result.entity}</td>
											<td>{result.format}</td>
											<td>{result.worker}</td>
											<td>{formatByteSize(result.size)}</td>
										</tr>
								)
								: <tr>
									<td colSpan={header.length}>
										<Stack direction="horizontal" className="justify-content-center">Query returned no results</Stack>
									</td>
								</tr>
						}
					</AdvancedTable>
					{
						loading && <div className="loading-overlay">
							<Loading/>
						</div>
					}
				</Col>
				<Col md={8}>
					<div>
						{
							selected && <FeedBrowserDetail
								id={selected.id}
								onAlert={onAlert}
								search={filterPaging.filter.search}
							/>
						}
					</div>
				</Col>
			</Row>
		</div>
	);
}

export default FeedBrowser;
