import React, {useEffect, useState} from 'react';
import {Button, Container, Form, Image} from "react-bootstrap";
import {loadLanguages} from "../../util/restUtil";
import {BsPersonFill, BsPersonFillLock} from "react-icons/bs";
import LocalizedText from "../controls/LocalizedText";
import {WithLang} from "../../type/component/WithLang";
import {WithAuth} from "../../type/component/WithAuth";
import {WithGoHome} from "../../type/component/WithGoHome";

export type HeaderProps =
	WithLang &
	WithAuth &
	WithGoHome & {
	onAdminFormRequested: () => any;
	onLanguageChanged: (l: string) => any;
};

function Header({auth, lang, onLanguageChanged, onAdminFormRequested, onGoHome}: HeaderProps) {
	const [languages, setLanguages] = useState<string[] | null>(null);

	const updateLanguages = () => {
		loadLanguages().then(setLanguages);
	};

	useEffect(updateLanguages, []);

	return (
		<header>
			<Container fluid="sm">
				<div className="d-flex justify-content-between align-items-center pt-2 pb-2">
					<div className="d-none d-md-block">
						<Button
							onClick={onGoHome}
							variant="link"
						>
							<Image src="/img/incomaker_logo_bile.svg" width={150} fluid={true}/>
						</Button>
					</div>
					<div className="text-center">
						<div className="d-block d-md-none">
							<Image src="/img/incomaker_logo_bile.svg" width={50} fluid={true}/>
						</div>
						<h1>
							P&nbsp;I&nbsp;V&nbsp;O
						</h1>
						<span className="d-none d-md-inline text-muted">
							<LocalizedText message="Plugin Integration and Validation Objects" lang={lang}/>
						</span>
					</div>
					<div className="header-right-section d-flex align-items-center justify-content-end">
						{
							auth ?
								<Button variant="link" onClick={onAdminFormRequested}>
									<BsPersonFillLock/>
								</Button>
								:
								<Button
									variant="link"
									onClick={onAdminFormRequested}
								>
									<BsPersonFill/>
								</Button>
						}
						{
							languages && (
								<Form>
									<Form.Group>
										<Form.Select size="sm" defaultValue={String(lang)}
													 onChange={(e) => onLanguageChanged(e.target.value)}>
											{languages.map((l) => <option key={l} value={l}>{l}</option>)}
										</Form.Select>
									</Form.Group>
								</Form>
							)
						}
					</div>
				</div>
			</Container>
		</header>
	);
}

export default Header;
