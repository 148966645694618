import React, {useCallback, useEffect, useState} from 'react';

import {Offcanvas} from "react-bootstrap";
import Header from "./Header";
import AlertBox from "../controls/AlertBox";
import {UserAlert, UserAlerts} from "../../type/UserAlert";
import {Auth, getAuthentication, getLanguage, setLanguage} from "../../util/sessionUtil";
import Footer from "./Footer";
import {getAppVersion, startValidationSession, validateLanguage} from "../../util/restUtil";
import AdminPanel from "./admin/AdminPanel";
import MainScreen from "./main/MainScreen";
import {isEmptyString} from "../../util/stringUtil";
import {ScreenSelection} from "../../type/ScreenSelection";
import PlatformTable from "./admin/platform/PlatformTable";
import {SessionRef} from "../../type/data/SessionRef";
import PluginTable from "./admin/plugin/PluginTable";
import {getDefaultLanguage} from "../../util/localizationUtil";
import FeedBrowser from "./admin/feeds/FeedBrowser";
import {clone} from "../../util/objectUtil";

function App() {
	const [alerts, setAlerts] = useState<UserAlerts>([]);
	const [auth, setAuth] = useState<Auth | null>(null);
	const [lang, setLang] = useState<string>(getDefaultLanguage());
	const [version, setVersion] = useState<string | boolean | null>(null);
	const [sessionRef, setSessionRef] = useState<SessionRef | undefined | null>();
	const [screenSelection, setScreenSelection] = useState<ScreenSelection>(ScreenSelection.Main);
	const [showAdminLogin, setShowAdminLogin] = useState<boolean>(false);

	const goHome = () => {
		setSessionRef(null);
		setScreenSelection(ScreenSelection.Main);
	}

	const refreshAuth = () => {
		setAuth(getAuthentication());
		setShowAdminLogin(false);
	};

	useEffect(refreshAuth, []);

	const refreshLang = (l?: string) => {
		if (isEmptyString(l)) l = getLanguage();
		validateLanguage(String(l)).then(
			(validLang: string) => {
				setLanguage(validLang);
				setLang(validLang);
			}
		);
	};

	useEffect(refreshLang, []);

	const loadVersion = () => {
		getAppVersion()
			.then(
				(v: string) => {
					setVersion(v);
				},
				() => {
					setVersion(false);
				}
			);
	};

	useEffect(loadVersion, []);

	const addAlert = useCallback(
		(alert: UserAlert) => {
			setAlerts([...alerts, clone(alert)]);
		},
		[alerts]
	);

	const resetAlerts = useCallback(
		() => setAlerts([]),
		[]
	);

	const removeAlert = useCallback(
		(alert: UserAlert) => {
			const i = alerts.indexOf(alert, 0);
			if (i > -1) {
				alerts.splice(i, 1);
				setAlerts([...alerts]);
			}
		},
		[alerts]
	);

	const startPluginValidation = useCallback(
		(pluginApiKey: string) => {
			startValidationSession(pluginApiKey, 'PlatformSpecific', false)
				.then(
					(ref: SessionRef) => {
						resetAlerts();
						setSessionRef(ref);
						setScreenSelection(ScreenSelection.Main);
					},
					(err) => {
						addAlert({type: 'danger', title: 'Error', message: String(err)});
					}
				);
		},
		[addAlert, resetAlerts]
	);

	return (
		<>
			<div className="main-wrapper">
				<Header
					auth={auth}
					lang={lang}
					onAdminFormRequested={() => setShowAdminLogin(true)}
					onLanguageChanged={refreshLang}
					onGoHome={goHome}
				/>
				<div>
					{
						screenSelection === ScreenSelection.Main && <MainScreen
							auth={auth}
							lang={lang}
							sessionRef={sessionRef}
							onAlert={addAlert}
							onResetAlerts={resetAlerts}
							onChangeSession={setSessionRef}
							onGoHome={goHome}
						/>
					}
					{
						screenSelection === ScreenSelection.AdminPlatforms && <PlatformTable lang={lang} onAlert={addAlert}/>
					}
					{
						screenSelection === ScreenSelection.AdminPlugins &&
						<PluginTable lang={lang} onAlert={addAlert} onPivoValidationRequested={startPluginValidation}/>
					}
					{
						screenSelection === ScreenSelection.AdminFeedBrowser && <FeedBrowser lang={lang} onAlert={addAlert}/>
					}
				</div>
				<Footer version={version}/>
				<Offcanvas show={showAdminLogin} onHide={() => setShowAdminLogin(false)} placement="end">
					<Offcanvas.Header closeButton>
						<Offcanvas.Title>Administrator Access</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<AdminPanel
							auth={auth}
							onConfirmed={refreshAuth}
							onCancelled={() => setShowAdminLogin(false)}
							onAlert={addAlert}
							onResetAlerts={resetAlerts}
							onScreenSelectionChanged={setScreenSelection}
						/>
					</Offcanvas.Body>
				</Offcanvas>
			</div>
			<AlertBox alerts={alerts} onRemoveAlert={removeAlert} onResetAlerts={resetAlerts}/>
		</>
	);
}

export default App;
