import XmlBeautify from "xml-beautify/dist/XmlBeautify";
import {isEmptyString} from "./stringUtil";

export function formatXmlForHumans(sourceXml) {
	if (isEmptyString(sourceXml)) return '';
	try {
		return new XmlBeautify().beautify(sourceXml);
	} catch (e) {
		return sourceXml;
	}
}

export function isXml(str) {
	if (isEmptyString(str)) return false;
	return (str?.startsWith('<?xml'));
}
