import conf from "../config/conf.json";
import {Auth, getAuthentication} from "./sessionUtil";
import {SessionRef} from "../type/data/SessionRef";
import {Result} from "../type/data/Result";
import {getCache, resetCache} from "./cacheUtil";
import {Paging} from "../type/Paging";
import {pagingToPagingRequest} from "./pagingUtil";
import {SessionPayloadPage} from "../type/data/SessionPayload";
import {Platform, PlatformCollection, PlatformPage} from "../type/data/Platform";
import {Plugin, PluginPage} from "../type/data/Plugin";
import {ImportStashDocumentFull} from "../type/data/ImportStashDocumentFull";
import {ImportStashDocumentPage} from "../type/data/ImportStashDocument";
import {FeedBrowserFilterPaging, filterToSearchRequest} from "../type/data/FeedBrowserFilter";

const API_BASE_URL = conf.API_URL;

function trimSlashes(str: string) {
	if (!str) return '';
	return str.replace(/^\/|\/$/g, '');
}

function getUrl(endpoint: string) {
	return [trimSlashes(API_BASE_URL), trimSlashes(endpoint)].join('/');
}

function getRequestOptions(method: string = 'GET', data: object | null = null) {
	const auth: Auth | null = getAuthentication();
	return {
		method: method,
		headers: {
			'Content-Type': 'application/json',
			'Authorization': auth === null ? 'unknown' : auth.apiKey
		},
		body: data === null ? null : JSON.stringify(data)
	};
}

function processRequest(url: string, requestOptions?: object): Promise<Response> {
	return fetch(getUrl(url), requestOptions)
		.then((response) => {
			if (!response.ok) {
				const options = {cause: response.status};
				if (response.headers.get('Content-Type') === 'application/json') {
					return response
						.json()
						.then((json) => {
							if (json.message) {
								throw new Error(json.message, options);
							}
							if (json.error) {
								throw new Error(json.error, options);
							}
							throw new Error(response.statusText, options);
						}, () => {
							throw new Error(response.statusText, options);
						});
				} else {
					throw new Error(response.statusText, options);
				}
			}
			return response;
		});
}

function processRequestJson(url: string, requestOptions?: object | undefined): Promise<any> {
	return processRequest(url, requestOptions)
		.then((response) => {
			return response.json();
		});
}

function getJson(url: string, params?: any): Promise<any> {
	if (params) {
		const original = new URLSearchParams(params);
		const cleaned = new URLSearchParams();
		original.forEach((value, key) => {
			if (value !== '' && value !== undefined && value !== "undefined")
				cleaned.set(key, value);
		});
		url = `${url}?${cleaned.toString()}`;
	}
	return processRequestJson(url, getRequestOptions());
}

function postJson(url: string, data: object | null = null): Promise<any> {
	return processRequestJson(url, getRequestOptions('POST', data));
}

function putJson(url: string, data: object | null = null): Promise<any> {
	return processRequestJson(url, getRequestOptions('PUT', data));
}

function get(url: string): Promise<Response> {
	return processRequest(url, getRequestOptions());
}

function del(url: string): Promise<any> {
	return processRequest(url, getRequestOptions('DELETE'));
}

function post(url: string, data: object | null = null): Promise<any> {
	return processRequest(url, getRequestOptions('POST', data));
}

function put(url: string, data: object | null = null): Promise<any> {
	return processRequest(url, getRequestOptions('PUT', data));
}

// STATUS AND VERSION

export function getAppVersion(): Promise<string> {
	return get('status/version')
		.then((response) => response.text());
}

export function verifyAdminAuth(): Promise<boolean> {
	return get('admin/status')
		.then(
			() => true,
			() => false
		);
}

// LANGUAGE

export function loadLanguages(): Promise<string[]> {
	return getCache('available_languages', () => getJson('language'));
}

export function loadDefaultLanguage(): Promise<string> {
	return getCache('default_language', () => getJson('language/default'));
}

export function validateLanguage(lang: string): Promise<string> {
	return loadLanguages()
		.then(
			(langs: string[]) => {
				if (langs.includes(lang)) {
					return lang;
				} else {
					return loadDefaultLanguage();
				}
			}
		);
}

// VALIDATION SESSIONS

export function startValidationSession(pluginApiKey: string, validationType: string = 'PlatformSpecific', force: boolean = false): Promise<SessionRef> {
	let url = `session/${pluginApiKey}/${validationType}`;
	if (force) url += '?force=true';
	return postJson(url);
}

export function loadValidationSessionResult(sessionRef: SessionRef, lang: string): Promise<Result> {
	return getJson(`session/${sessionRef.sessionId}/${lang}`);
}

export function restartValidationSession(sessionRef: SessionRef): Promise<null> {
	return put(`session/${sessionRef.sessionId}/restart`);
}

// ADMIN

export function loadRunningSessions(p: Paging): Promise<SessionPayloadPage> {
	return getJson(`admin/sessions-list`, pagingToPagingRequest(p));
}

// Platform

export function loadPlatformsPage(p: Paging): Promise<PlatformPage> {
	return getJson(`admin/platform/page/${p.page}`, pagingToPagingRequest(p));
}

export function loadPlatformsAll(): Promise<PlatformCollection> {
	return getCache('platforms-all', () => getJson(`admin/platform`));
}

export function savePlatform(p: Platform): Promise<Platform> {
	resetCache('platforms-all');
	if (!p.id) {
		return postJson(`admin/platform`, p);
	}
	return putJson(`admin/platform/${p.id}`, p);
}

export function deletePlatform(id: number): Promise<any> {
	resetCache('platforms-all');
	return del(`admin/platform/${id}`);
}

// Feed Formats

export function loadDataSourceTypeContactOptions(): Promise<string[]> {
	return getCache('feed-format-contact-options', () => getJson(`admin/platform/feed-format-contact`));
}

export function loadDataSourceTypeCategoryOptions(): Promise<string[]> {
	return getCache('feed-format-category-options', () => getJson(`admin/platform/feed-format-category`));
}

export function loadDataSourceTypeProductOptions(): Promise<string[]> {
	return getCache('feed-format-product-options', () => getJson(`admin/platform/feed-format-product`));
}

export function loadDataSourceTypeOrderOptions(): Promise<string[]> {
	return getCache('feed-format-order-options', () => getJson(`admin/platform/feed-format-order`));
}

export function loadDataSourceTypeCouponOptions(): Promise<string[]> {
	return getCache('feed-format-coupon-options', () => getJson(`admin/platform/feed-format-coupon`));
}

export function loadDataSourceTypeCouponTemplateOptions(): Promise<string[]> {
	return getCache('feed-format-coupon-template-options', () => getJson(`admin/platform/feed-format-coupon-template`));
}

export function loadDataSourceTypeInfoOptions(): Promise<string[]> {
	return getCache('feed-format-info-options', () => getJson(`admin/platform/feed-format-info`));
}

// Plugins

export function loadPluginsPage(p: Paging): Promise<PluginPage> {
	return getJson(`admin/plugin/page/${p.page}`, pagingToPagingRequest(p));
}

export function savePlugin(p: Plugin): Promise<Plugin> {
	if (!p.id) {
		return postJson(`admin/plugin`, p);
	}
	return putJson(`admin/plugin/${p.id}`, p);
}

export function deletePlugin(id: number): Promise<any> {
	return del(`admin/plugin/${id}`);
}

export function loadPluginStatusOptions(): Promise<string[]> {
	return getCache('plugin-status-options', () => getJson(`admin/plugin/plugin-status`));
}

export function loadPluginOfSession(session: SessionRef): Promise<Plugin> {
	return getJson(`session/${session.sessionId}/plugin`);
}


// Import Stash

export function loadImportStashDocumentFull(id: string): Promise<ImportStashDocumentFull> {
	return getJson(`admin/import-stash/${id}`);
}

export function searchImportStash(f: FeedBrowserFilterPaging): Promise<ImportStashDocumentPage> {
	return getJson(`admin/import-stash/search`, filterToSearchRequest(f));
}

export function importStashOptionsList(name: string): Promise<string[]> {
	return getJson(`admin/import-stash/list/${name}`);
}
