import React, {ReactElement} from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {State} from "../../../../type/data/State";
import Loading from "../../../controls/Loading";
import {BiQuestionMark} from "react-icons/bi";
import {BsBugFill, BsEmojiAngryFill, BsFillCheckCircleFill, BsFillExclamationOctagonFill, BsFillInfoCircleFill, BsStopFill} from "react-icons/bs";

export type StateProps = {
	state: State;
};

const ICON_SIZE = 25;
const ICON_OPACITY = 0.8;

const STATE_MAPPING: Map<string, ReactElement> = new Map<string, ReactElement>();
STATE_MAPPING.set('Exception', <BsEmojiAngryFill color="var(--red)" size={ICON_SIZE} opacity={ICON_OPACITY}/>);
STATE_MAPPING.set('Stopped', <BsStopFill color="var(--dark-gray)" size={ICON_SIZE} opacity={ICON_OPACITY}/>);
STATE_MAPPING.set('Warning', <BsFillExclamationOctagonFill color="var(--orange)" size={ICON_SIZE} opacity={ICON_OPACITY}/>);
STATE_MAPPING.set('Error', <BsBugFill color="var(--red)" size={ICON_SIZE} opacity={ICON_OPACITY}/>);
STATE_MAPPING.set('Success', <BsFillCheckCircleFill color="var(--green)" size={ICON_SIZE} opacity={ICON_OPACITY}/>);
STATE_MAPPING.set('Info', <BsFillInfoCircleFill color="var(--blue)" size={ICON_SIZE} opacity={ICON_OPACITY}/>);
STATE_MAPPING.set('InProgress', <Loading variant="primary" small={true} size={ICON_SIZE}/>);

function ValidationState({state}: StateProps) {
	const element = STATE_MAPPING.get(state.type) || <BiQuestionMark/>;
	return (
		<OverlayTrigger overlay={<Tooltip>{state.label}</Tooltip>}>
			<div className="d-flex">{element}</div>
		</OverlayTrigger>
	);
}

export default ValidationState;
