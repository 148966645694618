import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import Loading from "../../controls/Loading";
import AdvancedTable from '../../controls/AdvancedTable';
import {WithOnAlertProp} from "../../../type/component/WithAlertProps";
import {Paging} from "../../../type/Paging";
import {loadRunningSessions} from "../../../util/restUtil";
import {SessionRef} from "../../../type/data/SessionRef";
import {SessionPayload, SessionPayloadPage} from "../../../type/data/SessionPayload";
import {formatDateForHumans} from "../../../util/dateUtil";

const PAGE_SIZE: number = 10;

export type SessionsListFormProps = WithOnAlertProp & {
	onConfirmed: (sessionRef: SessionRef) => any;
	onCancelled: () => any;
};

function SessionsListModal({onConfirmed, onCancelled, onAlert}: SessionsListFormProps) {
	const [paging, setPaging] = useState<Paging>({search: '', page: 0, size: PAGE_SIZE, sorting: [{name: 'startTime', desc: true}]});
	const [loading, setLoading] = useState<boolean>(false);
	const [sessions, setSessions] = useState<SessionPayloadPage | null>(null);

	const loadSessions = () => {
		setLoading(true);
		loadRunningSessions(paging)
			.then(
				(results: SessionPayloadPage) => {
					setSessions(results);
					setLoading(false);
				},
				(err) => {
					setLoading(false);
					onAlert({type: 'danger', title: 'Error', message: String(err)});
				}
			);
	}

	useEffect(loadSessions, [paging, onAlert]);

	const header = [
		{name: '', label: 'Start'},
		{name: '', label: 'Finish'},
		{name: '', label: 'Plugin ID'},
		{name: '', label: 'Account'},
		{name: '', label: 'URL'},
		{name: '', label: 'Type'},
	];

	return (
		<Modal size="lg" show={true} onHide={onCancelled}>
			<Modal.Header closeButton>
				<Modal.Title>Available Validation Sessions</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{
					loading ?
						<Loading/>
						:
						<AdvancedTable
							header={header}
							onPagingChanged={setPaging}
							totalPages={sessions ? sessions.totalPages : 0}
							totalItems={sessions ? sessions.totalElements : 0}
							paging={paging}
						>
							{
								sessions ?
									sessions.content.map(
										(result: SessionPayload, index: number) =>
											<tr key={index} className="cursor-pointer"
												onClick={() => onConfirmed(result.sessionRef)}>
												<td>{formatDateForHumans(result.startTime)}</td>
												<td>{formatDateForHumans(result.finishTime)}</td>
												<td>{result.pluginId}</td>
												<td>{result.pluginAccount}</td>
												<td>{result.url}</td>
												<td>{result.validationType}</td>
											</tr>
									)
									: <></>
							}
						</AdvancedTable>
				}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onCancelled}>Close</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default SessionsListModal;
