import {isEmpty} from "./objectUtil";

export function isEmptyNumber(n: number | null | undefined) {
	return isEmpty(n) || n === 0;
}

export function isValidInteger(val: string | number | null | undefined): boolean {
	if (typeof val === 'number') return Number.isInteger(val);
	if (typeof val !== 'string') return false;
	if (val.trim().length === 0) return false;
	return Number.isInteger(Number(val));
}

export function integerToString(n: number | null | undefined): string {
	return (n === null || n === undefined || isNaN(n)) ? '' : String(n);
}

export function parseInteger(n: string | number | null | undefined): number | null {
	return isValidInteger(n) ? Number(n) : null;
}

export function formIntegerHelper(inputValue: string | number | null | undefined, originalValue: number | null | undefined): number | null | undefined {
	if (inputValue === '') return null;
	const i = parseInteger(inputValue);
	return i === null ? originalValue : i;
}


