import React from 'react';
import {containsLineBreaks} from "../../util/stringUtil";
import {formatJsonForHumans, isJson} from "../../util/jsonUtil";

export type SmartTextBoxProps = {
	text?: string | null;
};

function SmartTextBox({text}: SmartTextBoxProps) {
	if (isJson(text)) text = formatJsonForHumans(text);
	const displayPre = containsLineBreaks(text);
	const css = `smart-text-box ${displayPre ? "preformatted" : "plain"}`;
	return (
		<div className={css}>
			{
				displayPre ?
					<pre>{text}</pre>
					: <div className="text-break">{text}</div>
			}
		</div>
	);
}

export default SmartTextBox;
