/**
 * General purpose in-memory key-value cache
 */
const CACHE: { [key: string]: any } = {};

export function setCache(key: string, value: any): any {
	CACHE[key] = value;
	return value;
}

export function resetCache(key: string): any {
	return setCache(key, undefined);
}

export function getCache(key: string, valueProvider: () => any): any {
	const result = CACHE[key];
	if (result === undefined) return setCache(key, valueProvider());
	return result;
}
