import React, {useEffect, useState} from 'react';
import {SessionRef} from "../../../../type/data/SessionRef";
import {Result} from "../../../../type/data/Result";
import {loadValidationSessionResult, restartValidationSession} from "../../../../util/restUtil";
import Loading from "../../../controls/Loading";
import SuitResultControl from "./SuitResultControl";
import {BsArrowLeftCircleFill, BsSearch} from "react-icons/bs";
import {Button, Stack} from "react-bootstrap";
import LocalizedText from "../../../controls/LocalizedText";
import {WithOnAlertProp, WithResetAlertsProp} from "../../../../type/component/WithAlertProps";
import {WithLang} from "../../../../type/component/WithLang";
import {WithAuth} from "../../../../type/component/WithAuth";

export type SessionProps =
	WithOnAlertProp &
	WithResetAlertsProp &
	WithLang &
	WithAuth & {
	onNavigateBack: () => any;
	onExpiredSessionRestartRequested: () => any;
	onAdminSearchRequested: () => any;
	onAdminSessionListRequested: () => any;
	sessionRef: SessionRef;
};

function SessionControl(
	{
		auth,
		lang,
		sessionRef,
		onNavigateBack,
		onExpiredSessionRestartRequested,
		onAlert,
		onAdminSearchRequested,
		onAdminSessionListRequested
	}: SessionProps
) {
	const [result, setResult] = useState<Result | null>(null);

	const restartSession = () => {
		restartValidationSession(sessionRef)
			.then(
				() => {
					refreshResults();
				},
				(err) => {
					if (err.cause === 404) {
						onExpiredSessionRestartRequested();
						return;
					}
					onAlert({type: 'danger', title: 'Error', message: String(err)});
				}
			);
	}

	const refreshResults = () => {
		loadValidationSessionResult(sessionRef, lang)
			.then(
				(r: Result) => {
					setResult(r);
				},
				(err) => onAlert({type: 'danger', title: 'Error', message: String(err)})
			);
	};

	useEffect(refreshResults, [lang, sessionRef]);
	
	const resultsLoaded = () => {
		if (result == null) return;
		if (result.state.type === 'InProgress') {
			const intervalHandle: NodeJS.Timer = setTimeout(refreshResults, 3000);
			return () => {
				window.clearTimeout(intervalHandle);
			};
		}
	};

	useEffect(resultsLoaded, [result]);

	return (
		<div>
			<Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between">
				<Button variant="primary" type="submit" className="d-flex" size="sm" onClick={onNavigateBack}>
					<BsArrowLeftCircleFill size="1.5em" className="me-2"/>
					<LocalizedText message="Back" lang={lang}/>
				</Button>
				{
					auth && (
						<Stack direction="horizontal" gap={2}>
							<Button
								onClick={onAdminSearchRequested}
								variant="primary"
								className="d-flex align-items-center"
								size="sm"
							>
								<BsSearch size="1.5em" className="me-2"/>
								Search plugin...
							</Button>
							<Button
								onClick={onAdminSessionListRequested}
								variant="primary"
								className="d-flex align-items-center"
								size="sm"
							>
								Sessions...
							</Button>
						</Stack>
					)
				}
			</Stack>
			<div className="py-4">
				{
					result ?
						<SuitResultControl
							auth={auth}
							lang={lang}
							result={result}
							onRestartSession={restartSession}
							onAlert={onAlert}
							sessionRef={sessionRef}
						/>
						:
						<div className="d-flex justify-content-center align-items-center">
							<Loading variant="primary" message="Loading validation results..."/>
						</div>
				}
			</div>
		</div>
	);
}

export default SessionControl;
