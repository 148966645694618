import React, {useEffect, useState} from "react";
import {WithOnAlertProp} from "../../../../type/component/WithAlertProps";
import Loading from "../../../controls/Loading";
import AdvancedTable from "../../../controls/AdvancedTable";
import {Paging} from "../../../../type/Paging";
import {Platform, PlatformPage} from "../../../../type/data/Platform";
import {deletePlatform, loadPlatformsPage, savePlatform} from "../../../../util/restUtil";
import {translate} from "../../../../util/localizationUtil";
import {WithLang} from "../../../../type/component/WithLang";
import {Button, Form} from 'react-bootstrap';
import PlatformForm from "./PlatformForm";
import {clone} from "../../../../util/objectUtil";
import {BsPlus} from "react-icons/bs";

const PAGE_SIZE: number = 10;

export type AdminPlatformsProps =
	WithOnAlertProp &
	WithLang & {};

function PlatformTable({lang, onAlert}: AdminPlatformsProps) {
	const [loading, setLoading] = useState<boolean>(false);
	const [paging, setPaging] = useState<Paging>({page: 0, size: PAGE_SIZE, sorting: [{name: 'name', desc: false}]});
	const [results, setResults] = useState<PlatformPage | null>(null);
	const [form, setForm] = useState<Platform | null>(null);

	const refreshResults = () => {
		setLoading(true);
		loadPlatformsPage(paging)
			.then(
				(results: PlatformPage) => {
					setResults(results);
					setLoading(false);
				},
				(err) => {
					setLoading(false);
					onAlert({type: 'danger', title: 'Error', message: String(err)});
				}
			);
	}

	useEffect(refreshResults, [paging]);

	const saveData = (data: Platform) => {
		setLoading(true);
		savePlatform(data)
			.then((savedData: Platform) => {
				if (results?.content) {
					// update table data
					if (data.id === undefined) {
						results.content.unshift(savedData);
					} else {
						results.content = results.content.map(
							(p: Platform): Platform => {
								if (p.id === savedData.id) {
									return savedData;
								}
								return p;
							}
						);
					}
					setResults(clone(results));
				}

				setForm(null);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				onAlert({type: 'danger', title: 'Error', message: String(err)});
			});
	}

	const deleteData = (data: Platform) => {
		if (!data.id) return;
		setLoading(true);
		deletePlatform(data.id)
			.then(() => {
				// remove from table data
				if (results?.content) {
					// update table data
					results.content = results.content.filter((p) => p.id !== data.id);
					setResults(clone(results));
				}

				setForm(null);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				onAlert({type: 'danger', title: 'Error', message: String(err)});
			});
	}

	const header = [
		{name: 'id', label: 'ID'},
		{name: 'name', label: 'Name'},
		{name: 'importsSupported', label: 'Imports'},
		{name: 'eventsSupported', label: 'Events'},
		{name: 'trackingSupported', label: 'Tracking'},
		{name: 'dataSourceTypeContact', label: 'Contacts'},
		{name: 'dataSourceTypeProduct', label: 'Products'},
		{name: 'dataSourceTypeOrder', label: 'Orders'},
		{name: 'dataSourceTypeCoupon', label: 'Coupons'}
	];

	const renderFormat = (format: string) => {
		return <span
			className={format === 'Disabled' ? 'text-black-50' : ''}>
			{format}
		</span>
	}

	const add = () => {
		const data = {
			name: '',
			description: '',
			importsSupported: false,
			eventsSupported: false,
			trackingSupported: false,
			couponCreateSupported: false,
			productFullUpdateRequired: false,
			manualProductDeactivationRequired: false,
			dataSourceTypeContact: 'Disabled',
			dataSourceTypeCategory: 'Disabled',
			dataSourceTypeProduct: 'Disabled',
			dataSourceTypeOrder: 'Disabled',
			dataSourceTypeCoupon: 'Disabled',
			dataSourceTypeCouponTemplate: 'Disabled',
			dataSourceTypeInfo: 'Disabled'
		}
		setForm(data);
	}

	return (
		<div className="admin-panel p-4">
			<div className="d-flex flex-column align-items-center py-4">
				<h2 className="text-center">{translate('Platforms', lang)}</h2>
				<div>
					<Button
						onClick={add}
						variant="link"
						size="lg"
					>
						<BsPlus/>
						Add...
					</Button>
				</div>
			</div>

			<div className="py-2">
				<AdvancedTable
					header={header}
					onPagingChanged={setPaging}
					totalPages={results ? results.totalPages : 0}
					totalItems={results ? results.totalElements : 0}
					paging={paging}
				>
					{
						results ?
							results.content.map(
								(result: Platform, index: number) =>
									<tr key={index} className="cursor-pointer"
										onClick={() => setForm(result)}>
										<td>{result.id}</td>
										<td>
											<strong>{result.name}</strong>
											<div className="fs-6 text-small">{result.description}</div>
										</td>
										<td>
											<Form.Check
												disabled
												checked={result.importsSupported}
												type="switch"
											/>
										</td>
										<td>
											<Form.Check
												disabled
												checked={result.eventsSupported}
												type="switch"
											/>
										</td>
										<td>
											<Form.Check
												disabled
												checked={result.trackingSupported}
												type="switch"
											/>
										</td>
										<td>{renderFormat(result.dataSourceTypeContact)}</td>
										<td>{renderFormat(result.dataSourceTypeProduct)}</td>
										<td>{renderFormat(result.dataSourceTypeOrder)}</td>
										<td>{renderFormat(result.dataSourceTypeCoupon)}</td>
									</tr>
							)
							: <></>
					}
					{
						loading && <div className="loading-overlay">
							<Loading/>
						</div>
					}
				</AdvancedTable>
				{
					form && <PlatformForm
						data={form}
						onAlert={onAlert}
						onClose={() => setForm(null)}
						onDelete={deleteData}
						onSave={saveData}
					/>
				}
			</div>
		</div>
	);
}

export default PlatformTable;
