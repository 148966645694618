import {isEmpty} from "./objectUtil";

export function isEmptyString(str: string | null | undefined): boolean {
	return isEmpty(str) || str?.trim().length === 0;
}

export function hasContent(str: string | null | undefined): boolean {
	return !isEmptyString(str);
}

export function substr(str: string, start: number, length?: number): string {
	if (isEmptyString(str)) return '';
	return str.substring(start, length);
}

export function containsLineBreaks(str: string | null | undefined): boolean {
	if (str === null || str === undefined || str.trim().length === 0) return false;
	return str.includes("\n");
}
