import React from 'react';
import {translate} from "../../util/localizationUtil";

export type LocalizeMessageProps = {
	message: string;
	lang: string;
	containsHtml?: boolean;
};

function LocalizedText({message, lang, containsHtml}: LocalizeMessageProps) {
	if (containsHtml === true) {
		return <span
			dangerouslySetInnerHTML={{__html: translate(message, lang)}}
		/>
	}

	return <span>{translate(message, lang)}</span>;
}

export default LocalizedText;
