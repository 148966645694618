import {getCache, setCache} from "./cacheUtil";
import {substr} from "./stringUtil";

const SESSION_STORAGE_KEY = 'pivo_session';

export type Auth = {
	apiKey: string;
};

type SessionData = {
	adminAuth: Auth | null;
	language: string;
};

function getDefaultSession(): SessionData {
	return {
		adminAuth: null,
		language: substr(navigator.language, 0, 2)
	}
}

function loadSessionFromStorage(): SessionData | null {
	const str = localStorage.getItem(SESSION_STORAGE_KEY);
	if (str === null) return null;
	return JSON.parse(str);
}

function saveSessionToStorage(data: SessionData | null) {
	if (data === null) {
		localStorage.removeItem(SESSION_STORAGE_KEY);
		return;
	}
	localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(data));
}

function getSessionData(): SessionData {
	return getCache(
		SESSION_STORAGE_KEY,
		() => loadSessionFromStorage() || getDefaultSession()
	);
}

function setSessionData(data: SessionData): void {
	setCache(SESSION_STORAGE_KEY, data);
	saveSessionToStorage(data);
}

export function getAuthentication(): Auth | null {
	return getSessionData().adminAuth;
}

export function setAuthentication(auth: Auth | null) {
	const data = getSessionData();
	data.adminAuth = auth;
	setSessionData(data);
}

export function getLanguage(): string {
	return getSessionData().language;
}

export function setLanguage(lang: string): void {
	const data = getSessionData();
	data.language = lang;
	setSessionData(data);
}
