import Cs from '../lang/dictionary.cs.json';
import En from '../lang/dictionary.en.json';
import conf from "../config/conf.json";

interface Dictionary {
	[key: string]: string
}

interface Dictionaries {
	[key: string]: Dictionary
}

const DICTIONARIES: Dictionaries = {
	"cs": Cs,
	"en": En
}

export function getDefaultLanguage() {
	return DICTIONARIES[navigator.language] ? navigator.language : conf.DEFAULT_LANGUAGE;
}

export function getDictionary(lang: string): Dictionary {
	return DICTIONARIES[lang];
}

export function translate(str: string, lang: string): string {
	const dict = getDictionary(lang);
	if (!dict) return str;
	return dict[str] || str;
}
